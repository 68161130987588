//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import UpdPwd from './securityItem/updPwd'
export default {
  components: {
    UpdPwd
  },
  data () {
    return {
      data: []
    }
  },
  created () {
    if (this.hasPerm('sysUser:updatePwd')) {
      const updPwdMenu = {
        title: '賬戶密碼',
        description: '當前密碼強度',
        value: '強',
        actions: { title: '修改',
          callback: () => {
            this.$refs.updPwd.open(this.userInfo.id)
          }
        }
      }
      this.data.push(updPwdMenu)
    }
    const encryptedPhone = { title: '密保手機', description: '已綁定手機', value: '138****8293', actions: { title: '修改', callback: () => { this.$message.success('This is a message of success') } } }
    const encryptedProblem = { title: '密保問題', description: '未設置密保問題，密保問題可有效保護賬戶安全', value: '', actions: { title: '設置', callback: () => { this.$message.error('This is a message of error') } } }
    const encryptedEmail = { title: '備用郵箱', description: '已綁定郵箱', value: 'ant***sign.com', actions: { title: '修改', callback: () => { this.$message.warning('This is message of warning') } } }
    const encryptedMfa = { title: 'MFA 設備', description: '未綁定 MFA 設備，綁定後，可以進行二次確認', value: '', actions: { title: '綁定', callback: () => { this.$message.info('This is a normal message') } } }
    this.data.push(encryptedPhone)
    this.data.push(encryptedProblem)
    this.data.push(encryptedEmail)
    this.data.push(encryptedMfa)
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
  }
}
