//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysUserUpdatePwd } from '@/api/modular/system/userManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      visible_updPwd: false,
      userId: '',
      formUpdPwd: this.$form.createForm(this)
    }
  },
  methods: {
    open (id) {
      this.userId = id
      this.visible_updPwd = true
    },
    handleOkUpdPwd () {
      const { formUpdPwd: { validateFields } } = this
      validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          values.id = this.userId
          sysUserUpdatePwd(values).then((res) => {
            if (res.success) {
              this.$message.success('修改成功')
              this.handleCancel()
            } else {
              this.$message.error('修改失敗：' + res.message)
            }
          // eslint-disable-next-line handle-callback-err
          }).finally((err) => {
            this.confirmLoading = false
          })
        }
      })
    },
    handleCancel () {
      this.visible_updPwd = false
    },
    compareToFirstPassword (rule, value, callback) {
      const formUpdPwd = this.formUpdPwd
      if (value && value !== formUpdPwd.getFieldValue('newPassword')) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('請確認兩次輸入密碼的一致性！')
      } else {
        callback()
      }
    },
    validateToNextPassword (rule, value, callback) {
      const formUpdPwd = this.formUpdPwd
      if (value && this.confirmDirty) {
        formUpdPwd.validateFields(['confirm'], { force: true })
      }
      callback()
    }
  }
}
